/**
 * check if component appears on page
 * @param  {string} selectors - A DOMString containing one or more selectors to match
 * @return {boolean} - Return if selector matches
 */
const isThere = (selectors) => {
  return document.querySelector(selectors) !== null;
};

export { isThere };
